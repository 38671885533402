import { storage, firebase, db } from "./firebase";
import { getDownloadURL, getMetadata, ref } from "firebase/storage";
import { v4 as uuid } from "uuid";
export const getUUID = () => {
  const unique_id = uuid().replace(/-/gi, "");
  return unique_id;
};
/* import { doc, getDoc, getFirestore } from "firebase/firestore" */

const creatNewTourByText = (newTour) => {
  console.log("create new tour", newTour);
  const newTourID = getUUID();
  const guideObj = {
    id: guide.uid,
    full_name: guide.displayName ? guide.displayName : guide.username,
    profile_image: guide.avatar ? guide.avatar : "",
  };
  // Helper function to check for undefined and provide a default value
  const ensureDefined = (value, defaultValue) =>
    value === undefined ? defaultValue : value;

  const newTourObj = {
    id: ensureDefined(newTour.id, ""),
    stops: ensureDefined(newTour.stops, []),
    all_media: ensureDefined([], []),
    title: ensureDefined(newTour.title, ""),
    tour_guide: ensureDefined(newTour.guideObj, {}),
    main_sentense: ensureDefined(newTour.mainSentence, ""),
    config: ensureDefined(newTour.config, {}),
    notes: ensureDefined(newTour.notes, []),
  };
  tourRef.current = new Date();
  console.log("newTourObj", newTourObj);

  dispatch(setWantedTour(newTourObj));
  setData(newTourID);
  setShowKML(false);
};

export const updateWixID = function (tourID, wixID) {
  return new Promise(async (resolve, reject) => {
    try {
      await db.collection("tours").doc(tourID).update({
        wixID: wixID,
      });
      resolve("Product ID saved successfully!");
    } catch (error) {
      console.error("Error saving product ID: ", error);
      reject("Failed to save Product ID. Please try again.");
    }
  });
};

export const uploadFilePromise = function (files, basePath, setPercent, name) {
  return new Promise(function (myResolve, myReject) {
    if (files === "") {
      myReject(`The is no file`);
    }
    try {
      const uploadTask = storage
        .ref(`${basePath}/${/* files[0]. */ name}`)
        .put(files[0]);
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          //takes a snap shot of the process as it is happening
          const percent = Math.round(
            (snapShot.bytesTransferred / snapShot.totalBytes) * 100
          );
          setPercent(percent);
          //console.log(percent);
        },
        (err) => {
          //catches the errors
          myReject(err);
        },
        () => {
          // gets the functions from storage refences the image storage in firebase by the children
          // gets the download url then sets the image from firebase as the value for the imgUrl key:
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            myResolve(url);
          });
        }
      );
    } catch (error) {
      myReject(error);
    }
  });
};

export const uploadFileWithTypePromise = function (
  files,
  basePath,
  setPercent,
  name
) {
  return new Promise(function (myResolve, myReject) {
    if (files === "") {
      myReject(`The is no file`);
    }
    try {
      const uploadTask = storage
        .ref(`${basePath}/${/* files[0]. */ name}`)
        .put(files[0], { contentType: files[0].type });
      //initiates the firebase side uploading
      uploadTask.on(
        "state_changed",
        (snapShot) => {
          const percent = Math.round(
            (snapShot.bytesTransferred / snapShot.totalBytes) * 100
          );
          setPercent(percent);
        },
        (err) => {
          //catches the errors
          myReject(err);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((url) => {
            myResolve(url);
          });
        }
      );
    } catch (error) {
      myReject(error);
    }
  });
};

export const deleteFilePromise = function (url) {
  return new Promise(function (myResolve, myReject) {
    try {
      let pictureRef = storage.refFromURL(url);
      pictureRef
        .delete()
        .then(() => {
          myResolve(true);
        })
        .catch((err) => {
          if (err.code.includes("object-not-found"))
            myReject("object-not-found");
          myReject(false);
        });
    } catch (error) {
      if (error.code.includes("storage/invalid-argument"))
        myReject("storage/invalid-argument");
      myReject(error);
    }
  });
};

export const addNewUserFirestore = function (user) {
  return new Promise(function (myResolve, myReject) {
    try {
      //firebase.firestore().collection('saasUsers').doc(user.uid).set(user).then(() => {
      firebase
        .firestore()
        .collection("saasCustomers")
        .doc(user.uid)
        .set(user)
        .then(() => {
          myResolve(true);
        })
        .catch((err) => {
          myReject(err);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const getAIData = function (userID) {
  return new Promise(function (myResolve, myReject) {
    try {
      firebase
        .firestore()
        .collection("AIarea")
        .doc(userID)
        .get()
        .then((snapshot) => {
          myResolve(snapshot.data());
        })
        .catch((e) => {
          console.log(e);
          myReject(null);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const setUserVoiceId = function (voiceId, userId) {
  return new Promise(function (myResolve, myReject) {
    try {
      console.log("set voiceId firebase", voiceId, userId);
      firebase
        .firestore()
        .collection("AIarea")
        .doc(userId)
        .set({ voiceId: voiceId }, { merge: true }) // Use set with merge option
        .then(() => {
          myResolve(voiceId);
        })
        .catch((err) => {
          myReject(err);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const setVoiceURLsample = function (voiceURL, userId, voiceSettings) {
  return new Promise(function (myResolve, myReject) {
    try {
      console.log("set voiceURL firebase", voiceURL, userId, voiceSettings);
      firebase
        .firestore()
        .collection("AIarea")
        .doc(userId)
        .set({ voiceURL: voiceURL, voiceSettings }, { merge: true }) // Use set with merge option
        .then(() => {
          myResolve(voiceURL);
        })
        .catch((err) => {
          myReject(err);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const getAISettings = function (userId) {
  return new Promise(function (myResolve, myReject) {
    try {
      firebase
        .firestore()
        .collection("AIarea")
        .doc(userId)
        .get()
        .then((snapshot) => {
          myResolve(snapshot.data());
        })
        .catch((e) => {
          console.log(e);
          myReject(null);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const deleteVoiceDB = function (userId) {
  return new Promise(function (myResolve, myReject) {
    try {
      firebase
        .firestore()
        .collection("AIarea")
        .doc(userId)
        .set({ voiceId: null, voiceURL: null }, { merge: true })
        .then(() => {
          myResolve(true);
        })
        .catch((err) => {
          myReject(err);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const saveAItext = async function (userID, text) {
  try {
    await firebase
      .firestore()
      .collection("AIarea")
      .doc(userID)
      .set({ AItext: text }, { merge: true });
    return true;
  } catch (error) {
    console.log("error", error);
    return false;
  }
};

export const updateUserFirestore = function (user) {
  return new Promise(function (myResolve, myReject) {
    try {
      firebase
        .firestore()
        .collection("saasCustomers")
        .doc(user.uid)
        .set(user)
        .then(() => {
          myResolve(true);
        })
        .catch((err) => {
          myReject(err);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const checkSaaSCoupon = function (coupon) {
  return new Promise(function (myResolve, myReject) {
    try {
      firebase
        .firestore()
        .collection("saasCoupons")
        .doc(coupon)
        .get()
        .then((snapshot) => {
          myResolve(true);
        })
        .catch((e) => {
          console.log(e);
          myReject(null);
        });
    } catch (error) {
      myReject(error);
    }
  });
};

export const moveTourToDeleted = function (tourId) {
  const db = firebase.firestore();
  const tourRef = db.collection("tours").doc(tourId);
  const deletedTourRef = db.collection("deletedTours").doc(tourId);

  const userConfirmed = window.confirm(
    "Are you sure you want to delete this tour?"
  );
  if (!userConfirmed) {
    return Promise.reject("User cancelled delete operation");
  }

  return db.runTransaction((transaction) => {
    return transaction.get(tourRef).then((tourDoc) => {
      if (!tourDoc.exists) {
        throw "Tour not found";
      }

      transaction.set(deletedTourRef, tourDoc.data());
      transaction.delete(tourRef);
    });
  });
};

export const getUserByID = function (userID) {
  return new Promise(function (myResolve, myReject) {
    //firebase.firestore().collection("saasUsers").doc(`${userID}`).get().then((snapshot) => {
    firebase
      .firestore()
      .collection("saasCustomers")
      .doc(`${userID}`)
      .get()
      .then((snapshot) => {
        myResolve(snapshot.data());
      })
      .catch((e) => {
        console.log(e);
        myReject(null);
      });
  });
};

export const createSellingWebTour = function (tour) {
  return new Promise(async function (myResolve, myReject) {
    try {
      console.log("start creating...", tour);
      const tourDoc = await firebase
        .firestore()
        .collection("web_tour")
        .doc(tour.id)
        .get();
      console.log("tourDoc", tourDoc);
      if (tourDoc.exists) {
        return myResolve("Document with this tourID already exists.");
      }
      console.log("AFTER!!!");
      let stopLocations = [];
      tour.stops.map((stop) => {
        stopLocations.push({
          lat: stop.stop_location.latitude,
          lng: stop.stop_location.longitude,
        });
      });
      let htmlString = "";
      if (tour.notes && tour.notes.length > 0) {
        htmlString = `<div><h2 style="margin-bottom:15px;">What Awaits You on the Route?</h2>`;
        tour.notes.forEach((item) => {
          htmlString += `
          <h3>${item.question}</h3>
          <p>${item.answer}</p>
          `;
        });
        htmlString += "</div>";
      } else {
        htmlString = `<div>
  <h2 style="margin-bottom:15px;">What Awaits You on the Route?</h2>
  
  <h3>🏛️ Colosseum</h3>
  <p>We'll start with a visit to the Colosseum 🏛️, one of Rome's most iconic landmarks. This ancient amphitheater, rich in history, offers a glimpse into the grandeur of the Roman Empire.</p>
  
  <h3>🕍 Pantheon</h3>
  <p>Next, we'll visit the Pantheon 🕍, a marvel of ancient engineering. Its massive dome and oculus are a testament to Roman architectural ingenuity.</p>
  
  <h3>🍝 Piazza Navona</h3>
  <p>We'll stop at Piazza Navona 🍝, a vibrant square surrounded by stunning baroque architecture. Here, you can enjoy a taste of authentic Italian gelato or a cappuccino.</p>
  
  <h3>🎨 Vatican Museums</h3>
  <p>Afterward, we'll explore the Vatican Museums 🎨, home to an extensive collection of art and historical artifacts. Don't miss the Sistine Chapel with Michelangelo's breathtaking frescoes.</p>
  
  <h3>⛪ St. Peter's Basilica</h3>
  <p>We'll continue to St. Peter's Basilica ⛪, the heart of the Vatican and one of the most important religious sites in the world. The basilica's grandeur is unmatched.</p>
  
  <h3>🌅 Gianicolo Hill</h3>
  <p>We'll finish with a panoramic view 🌅 from Gianicolo Hill. This spot offers a breathtaking view of Rome, allowing you to take in the beauty of the Eternal City in all its glory.</p>
</div>`;
      }

      // Specify the source folder in Firebase Storage
      const sourceFolderPath = "toursWeb/master/";
      const destinationFolderPath = `toursWeb/${tour.id}/`;

      // Function to copy files from source to destination
      // Function to copy files from source to destination
      async function copyFolder(sourcePath, destinationPath) {
        console.log("Copying folder", sourcePath, "to", destinationPath);

        // Ensure that paths end with a slash
        if (!sourcePath.endsWith("/")) sourcePath += "/";
        if (!destinationPath.endsWith("/")) destinationPath += "/";

        const listResult = await firebase.storage().ref(sourcePath).listAll();
        const fileURLs = {};
        console.log("listResult", listResult);

        // Copy files
        for (const itemRef of listResult.items) {
          const fileName = itemRef.name;
          const fileRef = firebase.storage().ref(destinationPath + fileName);

          // Get the file's download URL
          const downloadURL = await itemRef.getDownloadURL();

          // Fetch the file data
          const fileData = await fetch(downloadURL).then((res) => res.blob());

          // Upload the file to the destination
          await fileRef.put(fileData);

          // Get the new download URL
          const newDownloadURL = await fileRef.getDownloadURL();
          fileURLs[destinationPath + fileName] = newDownloadURL;
        }

        // Recursively copy subfolders
        for (const folderRef of listResult.prefixes) {
          const subFolderPath = folderRef.fullPath.replace(sourcePath, "");
          const subFolderURLs = await copyFolder(
            sourcePath + subFolderPath,
            destinationPath + subFolderPath
          );
          Object.assign(fileURLs, subFolderURLs);
        }

        return fileURLs;
      }

      console.log("before copyFolder", sourceFolderPath, destinationFolderPath);
      // Copy the folder and get the file URLs
      const fileURLs = await copyFolder(
        sourceFolderPath,
        destinationFolderPath
      );
      console.log("fileURLs", fileURLs);
      // Add file URLs to the tour object
      //tour.fileURLs = fileURLs;

      // Set the new document in the web_tour collection
      const newObj = {
        gid: tour.tour_guide.id,
        guideProfileImgInput: {
          file: [],
          url: fileURLs[`toursWeb/${tour.id}/profileImg.webp`],
        },
        tourImageInput: {
          file: [],
          url: fileURLs[`toursWeb/${tour.id}/mainImg.webp`],
          //url: fileURLs[`toursWeb/${tour.id}/mainImg_resize`]
        },

        tourVidInput: {
          file: [],
          url: fileURLs[`toursWeb/${tour.id}/mainVid.mp4`]
            ? fileURLs[`toursWeb/${tour.id}/mainVid.mp4`]
            : "",
        },

        recommendations: [
          {
            imagUniqueID: "review1.webp",
            imageFile: [],
            recImg:
              fileURLs[`toursWeb/${tour.id}/recommendations/review1.webp`],
            recommendantName: "Alex Johnson",
            stars: "5",
            tourRecommendant:
              "The historic city tour was fascinating! The self-guided format let me explore freely, and the insights at each stop were spot-on. A must-try for history lovers!",
          },
          {
            imagUniqueID: "review2.webp",
            imageFile: [],
            recImg:
              fileURLs[`toursWeb/${tour.id}/recommendations/review2.webp`],
            recommendantName: "Jordan Smith",
            stars: "5",
            tourRecommendant:
              "The coastal adventure tour was stunning! Beautiful routes and easy navigation made for a perfect day out. Highly recommend for anyone wanting a peaceful yet engaging experience.",
          },
          {
            imagUniqueID: "review3.webp",
            imageFile: [],
            recImg:
              fileURLs[`toursWeb/${tour.id}/recommendations/review1.webp`],
            recommendantName: "Taylor Brown",
            stars: "5",
            tourRecommendant:
              "Loved the cultural landmarks tour! The audio guides were informative, and the trivia was fun. A great way to learn about local traditions at your own pace.",
          },
        ],
        paymentcurrency: "USD",
        price: "20",
        title: tour.title,
        tourAgeGroupP: "10-99",
        logoImageInput: {
          file: [],
          url: tour.tour_guide.profile_image
            ? tour.tour_guide.profile_image
            : fileURLs[`toursWeb/${tour.id}/logoImg.webp`]
            ? fileURLs[`toursWeb/${tour.id}/logoImg.webp`]
            : "",
        },
        contactNumber: "",
        tourImagesArray: [
          {
            file: [],
            name: "tourImg1_resize.jpg",
            url: fileURLs[
              `toursWeb/${tour.id}/tourImagesArray/tourImg1_resize.jpg`
            ],
          },
          {
            file: [],
            name: "tourImg2_resize.jpg",
            url: fileURLs[
              `toursWeb/${tour.id}/tourImagesArray/tourImg2_resize.jpg`
            ],
          },
          {
            file: [],
            name: "tourImg3_resize.jpg",
            url: fileURLs[
              `toursWeb/${tour.id}/tourImagesArray/tourImg3_resize.jpg`
            ],
          },
          {
            file: [],
            name: "tourImg4_resize.jpg",
            url: fileURLs[
              `toursWeb/${tour.id}/tourImagesArray/tourImg4_resize.jpg`
            ],
          },
          {
            file: [],
            name: "tourImg5_resize.jpg",
            url: fileURLs[
              `toursWeb/${tour.id}/tourImagesArray/tourImg5_resize.jpg`
            ],
          },
          {
            file: [],
            name: "tourImg6_resize.jpg",
            url: fileURLs[
              `toursWeb/${tour.id}/tourImagesArray/tourImg6_resize.jpg`
            ],
          },
        ],
        tourLocactionP: tour.location ? tour.location : "Rome, italy",
        tourPriceP: "25$",
        tourTimeInfoP: "sun-thu, 10:00-20:00",
        tourTypeP: "Culinary, history",
        tourdescription:
          "Discover Rome’s most iconic landmarks on this self-guided tour. Visit the Colosseum, Vatican City, and the Roman Forum, while uncovering hidden gems and vibrant piazzas. With rich historical insights and local tips, experience the Eternal City at your own pace, immersing yourself in its timeless charm and beauty.",
        tourLocations: stopLocations,
        guideDescription:
          "I’m a passionate local guide with a love for history and culture. I’ve been leading tours for over 10 years, sharing my knowledge and insights with visitors from around the world. Join me on a journey through Rome’s fascinating past and vibrant present, and let me show you the city’s hidden gems and iconic landmarks.",
        guideName: tour.tour_guide.name ? tour.tour_guide.name : "Guide Name",
        HTMLtext: htmlString,
      };
      console.log("creating new object, web_tour", newObj);
      await firebase
        .firestore()
        .collection("web_tour")
        .doc(tour.id)
        .set(newObj);

      myResolve(true);
    } catch (error) {
      myReject(error);
    }
  });
};

export const getDguideUserByID = function (userID) {
  return new Promise(function (myResolve, myReject) {
    //firebase.firestore().collection("saasUsers").doc(`${userID}`).get().then((snapshot) => {
    firebase
      .firestore()
      .collection("users")
      .doc(`${userID}`)
      .get()
      .then((snapshot) => {
        myResolve(snapshot.data());
      })
      .catch((e) => {
        console.log(e);
        myReject(null);
      });
  });
};

export const getAllProduct = function () {
  return new Promise(async function (myResolve, myReject) {
    const allProduct = {};
    firebase
      .firestore()
      .collection("products")
      .where("active", "==", true)
      .get()
      .then((snapshot) => {
        snapshot.forEach(async (prod) => {
          //console.log("prod", prod);
          allProduct[prod.id] = prod.data();
          if (allProduct[prod.id].name === "Advanced Plan")
            allProduct[prod.id]["popular"] = true;
          const priceSnapshot = await prod.ref.collection("prices").get();
          allProduct[prod.id]["prices"] = {};
          priceSnapshot.forEach((priceDoc) => {
            const priceData = priceDoc.data();
            if (priceData.interval === "month") {
              allProduct[prod.id]["month"] = priceData.unit_amount / 100;
              allProduct[prod.id]["monthId"] = priceDoc.id;
            } else {
              allProduct[prod.id]["year"] = priceData.unit_amount / 100;
              allProduct[prod.id]["yearId"] = priceDoc.id;
            }
            //allProduct[prod.id].prices[priceData.interval] = priceData
          });
        });
        setTimeout(function () {
          myResolve(allProduct);
        }, 2000);
      });
  });
};

export const checkTourExistence = async function (tourID) {
  try {
    console.log("checkTourExistence tourID", tourID);
    const docRef = firebase.firestore().collection("tours").doc(tourID);
    console.log("docRef", docRef);
    const doc = await docRef.get();
    console.log("doc", doc.exists);
    console.log(
      "doc.data()",
      doc.exists && doc.data().tour_guide.id !== tourID
    );
    if (doc.exists) return doc.data().tour_guide.id !== tourID;
    else return false;
  } catch (error) {
    console.log("Error checking tour existence:", error);
    return false;
  }
};

export const saveTourFirebase = function (updateTour) {
  return new Promise(async function (myResolve, myReject) {
    try {
      //updateTour.modify_date = Date()
      //console.log("updateTour", updateTour);
      await firebase
        .firestore()
        .collection("tours")
        .doc(updateTour.id)
        .set(updateTour);
      myResolve(updateTour);
    } catch (error) {
      console.log("ERROR in Add saveTourFirebase", error);
      myReject(error);
    }
  });
};

const checkUserAllow = function (userID, tourID) {
  return new Promise(async function (myResolve, myReject) {
    myResolve(userID === tourID);
  });
};

export const GetUser = function (userID) {
  return new Promise(async function (myResolve, myReject) {
    try {
      firebase
        .firestore()
        .collection("saasCustomers")
        .doc(userID)
        .get()
        .then((user) => {
          myResolve(user.data());
        })
        .catch((err) => {
          console.log("Can't get tours of this guide", err);
          myReject(err);
        });
    } catch (error) {
      console.log("error", error);
      myReject(error);
    }
  });
};

export const GetAllUserTours = function (guideID, userID) {
  return new Promise(async function (myResolve, myReject) {
    try {
      let allTours = [];
      if (!guideID || !userID) myResolve([]);
      console.log("guideID", guideID, userID);
      firebase
        .firestore()
        .collection("URLusage")
        .where("uID", "==", userID)
        .get()
        .then(async (tours) => {
          if (!tours.empty) {
            let data = await Promise.all(
              tours.docs.map(async (tour) => {
                allTours.push(tour.data().tourID);
              })
            );
            myResolve(allTours);
          } else {
            myResolve([]);
          }
        })
        .catch((err) => {
          console.log("Can't get tours of this guide", err);
          myReject(err);
        });
    } catch (error) {
      console.log("error", error);
      myReject(error);
    }
  });
};

export const getTourData = (tourID) => {
  return new Promise(async function (myResolve, myReject) {
    firebase
      .firestore()
      .collection("approval_saas")
      .doc(tourID)
      .get()
      .then((tour) => {
        if (tour.data()) {
          let data = tour.data();
          //console.log("data", data)
          let res = {
            title: data.title,
            tour_guide: data.tour_guide,
            tour_image: data.tour_image
              ? data.tour_image
              : data.stops[0].loc_pics[0].url,
            config: data.config,
          };
          //console.log("res", res)
          myResolve(res);
        }
      })
      .catch((err) => {
        console.log("Can't get tours of this guide", err);
        myReject({});
      });
  });
};

export const removeAPI = async function (uID, tourID, allAPI) {
  return new Promise(async function (myResolve, myReject) {
    //allAPI[idx].active = false;
    try {
      const newDoc = { ...allAPI[tourID][uID], active: false };
      allAPI[tourID][uID] = newDoc;
      firebase.firestore().collection("URLusage").doc(newDoc.docID).set(newDoc);
      myResolve(allAPI);
    } catch (error) {
      console.log("err", error);
      myReject(error);
    }
  });
};

export const updateTourProfile = async function (tour) {
  return new Promise(async function (myResolve, myReject) {
    try {
      let docRef = firebase.firestore().collection("tours").doc(tour.id);
      let updateTour = {
        duration: tour.duration,
        title: tour.title,
        language: tour.language,
        generalLocation: tour.generalLocation,
        location: tour.location,
        difficulty: tour.difficulty,
        type: tour.tourType.map((obj) => obj.value),
        equipment: tour.equipment.map((obj) => obj.value),
        tour_guide: tour.tour_guide,
        main_sentense: tour.main_sentense,
        notes: tour.notes,
        starting_name: tour.starting_name,
        starting_video: tour.starting_video,
        starting_url: tour.starting_url,
        starting_type: tour.starting_type,
        config: tour.config,
      };

      docRef
        .update(updateTour)
        .then((res) => {
          console.log("After update to the bsisash", res);
          myResolve(docRef.id);
        })
        .catch((err) => {
          console.log("Error set link to header");
          myReject(err);
        });
    } catch (error) {
      console.log("ERROR in Add updateTourProfile", error);
      myReject(error);
    }
  });
};

export const addLinkForUser = async function (
  gID,
  uID,
  tID,
  imageUrl,
  description,
  title
) {
  return new Promise(async function (myResolve, myReject) {
    try {
      const link = {
        imageUrl: imageUrl,
        description: description,
        title: title,
        gID: gID,
        uID: uID,
        tID: tID,
      };
      console.log("link", link);
      if (!link.tID || !link.gID || !link.uID)
        myReject("Error in addLinkForUser");

      let docRef = firebase.firestore().collection("URLheader").doc();
      console.log("docRef", docRef.id);
      if (!docRef.id) myReject("Error in addLinkForUser");
      docRef
        .set(link)
        .then((res) => {
          console.log("int then functions", res);
          myResolve(docRef.id);
        })
        .catch((err) => {
          console.log("Error set link to header");
          myReject(err);
        });
    } catch (error) {
      console.log("ERROR in Add addLinkForUser", error);
      myReject(error);
    }
  });
};

export const addAPI = async function (
  ownerID,
  uID,
  tourID,
  allAPI,
  minDate,
  maxDate,
  maxUsage
) {
  return new Promise(async function (myResolve, myReject) {
    try {
      const APIparams = {
        uId: uID,
        tourID: tourID,
        creationDate: Date(),
        active: true,
        maxDate: maxDate,
        minDate: minDate,
        maxUsage: Number(maxUsage),
        uniqueMID: [],
        usage: {},
      };
      //allAPI.push(APIparams)
      if (allAPI[tourID]) {
        allAPI[tourID][uID] = APIparams;
      } else {
        if (!allAPI) allAPI = {};
        allAPI[tourID] = {};
        allAPI[tourID][uID] = APIparams;
      }

      firebase
        .firestore()
        .collection("saasAPI")
        .doc(ownerID)
        .set({ allAPI: allAPI }, { merge: true })
        .then((resp) => {
          console.log("resp", resp);
          myResolve(allAPI);
        })
        .catch((err) => console.log("Error in firebase", err));
    } catch (error) {
      console.log("ERROR in Add API", error);
      myReject(error);
    }
  });
};

//usage change
export const addAPIPromise = async function (
  ownerID,
  uID,
  tourID,
  minDate,
  maxDate,
  maxUsage,
  couponNum = 0,
  allCoupons = [],
  budget = 0
) {
  return new Promise(async function (myResolve, myReject) {
    try {
      const APIparams = {
        uID: uID,
        tourID: tourID,
        creationDate: Date(),
        active: true,
        maxDate: maxDate,
        minDate: minDate,
        maxUsage: Number(maxUsage),
        usage: {},
        gID: ownerID,
      };
      if (couponNum > 0) {
        APIparams.coupon = {
          available: Number(couponNum),
          budget: Number(budget),
          budgetUsage: 0,
        };
        APIparams.allCoupons = allCoupons;
      }
      //allAPI.push(APIparams)
      /* if (allAPI[tourID]) {
        allAPI[tourID][uID] = APIparams;
      } else {
        if (!allAPI) allAPI = {};
        allAPI[tourID] = {};
        allAPI[tourID][uID] = APIparams;
      } */

      //allAPI[uID][tourID] = APIparams
      //console.log("ownerID", ownerID, tourID)
      //console.log("allAPI", allAPI)
      firebase
        .firestore()
        .collection("URLusage")
        .doc()
        .set(APIparams, { merge: true })
        .then((resp) => {
          console.log("resp", resp);
          myResolve(APIparams);
        })
        .catch((err) => {
          console.log("Error in firebase", err);
          myReject(err);
        });
    } catch (error) {
      console.log("ERROR in Add API", error);
      myReject(error);
    }
  });
};

//usage change
export const updateAPIPromise = async function (
  minDate,
  maxDate,
  maxUsage,
  docID
) {
  return new Promise(async function (myResolve, myReject) {
    try {
      const APIparams = {
        modifyDate: Date(),
        active: true,
        maxDate: maxDate,
        minDate: minDate,
        maxUsage: Number(maxUsage),
      };
      console.log("update tour", docID, APIparams);

      firebase
        .firestore()
        .collection("URLusage")
        .doc(docID)
        .set(APIparams, { merge: true })
        .then((resp) => {
          console.log("resp", resp);
          myResolve(APIparams);
        })
        .catch((err) => {
          console.log("Error in firebase", err);
          myReject(err);
        });
    } catch (error) {
      console.log("ERROR in Add API", error);
      myReject(error);
    }
  });
};

export const getAPIPromise = async function (ownerID) {
  return new Promise(async function (myResolve, myReject) {
    firebase
      .firestore()
      .collection("URLusage")
      .where("gID", "==", ownerID)
      .where("active", "==", true)
      .get()
      .then((querySnapshot) => {
        let docs = {};
        querySnapshot.forEach((doc) => {
          if (!docs[doc.data().tourID]) docs[doc.data().tourID] = {};
          if (!docs[doc.data().tourID][doc.data().uID])
            docs[doc.data().tourID][doc.data().uID] = {};
          if (docs[doc.data().tourID][doc.data().uID].usage) return;
          docs[doc.data().tourID][doc.data().uID] = {
            ...doc.data(),
            docID: doc.id,
          };

          //docs.push(doc.data());
        });

        myResolve({ allAPI: docs });
      })
      .catch((e) => {
        console.log(e);
        myReject(null);
      });
  });
};

export const getAPI = async function (ownerID) {
  return new Promise(async function (myResolve, myReject) {
    console.log("ownerID", ownerID);
    firebase
      .firestore()
      .collection("saasAPI")
      .doc(`${ownerID}`)
      .get()
      .then((snapshot) => {
        myResolve(snapshot.data());
      })
      .catch((e) => {
        console.log(e);
        myReject(null);
      });
  });
};

export const getHeaderLinks = async function (ownerID) {
  return new Promise(async function (myResolve, myReject) {
    firebase
      .firestore()
      .collection("URLheader")
      .where("gID", "==", ownerID)
      .get()
      .then((querySnapshot) => {
        let docs = [];
        querySnapshot.forEach((doc) => {
          docs.push({ ...doc.data(), id: doc.id });
        });

        myResolve(docs);
      })
      .catch((e) => {
        console.log(e);
        myReject(null);
      });
  });
};

const editAllMediaUrl = async function (allMediaArr, folderID) {
  return new Promise(async function (myResolve, myReject) {
    allMediaArr.map(async (media, idx) => {
      if (media.type.includes("video")) {
        console.log("CHeck _compress", media);
        const imageRef = storage().ref(`${folderID}/${media.name}_compress`);
        const url = await imageRef.getDownloadURL().catch((error) => {
          console.log("throw error not find compress resize in all_media tour");
        });
        console.log("FOUND URL", url);
        if (url) allMediaArr[idx].url = url;
      }
    });
  });
};

const fileStorageRef = function (
  folderID,
  fileName,
  type,
  subFolder = "stopMedia"
) {
  return new Promise(async function (myResolve, myReject) {
    try {
      let compressFileRef = storage.ref(
        subFolder
          ? `${folderID}/${subFolder}/${fileName}`
          : `${folderID}/${fileName}`
      );
      await compressFileRef
        .getDownloadURL()
        .then((url) => {
          myResolve(url);
        })
        .catch(async (error) => {
          compressFileRef = storage.ref(
            subFolder
              ? `${folderID}/${subFolder}/${fileName}`
              : `${folderID}/${fileName}`
          );
          await compressFileRef
            .getDownloadURL()
            .then((url) => {
              myResolve(url);
            })
            .catch(async (error) => {
              compressFileRef = storage.ref(
                subFolder
                  ? `${folderID}/${subFolder}/${fileName}.${type}`
                  : `${folderID}/${fileName}.${type}`
              );
              await compressFileRef
                .getDownloadURL()
                .then((url) => {
                  myResolve(url);
                })
                .catch((error) => {
                  if (error.code === "storage/object-not-found")
                    myReject("storage/object-not-found");
                });
            });
        });
    } catch (error) {
      console.log("GENERAL ERROR");
      myReject("false");
    }
  });
};

const fileStorageRefNoSubFolder = function (folderID, fileName, type) {
  return new Promise(async function (myResolve, myReject) {
    try {
      let compressFileRef = storage.ref(
        subFolder ? `${folderID}/${fileName}` : `${folderID}/${fileName}`
      );
      //console.log("fileStorageRef 1", `${folderID}/${fileName}`)
      await compressFileRef
        .getDownloadURL()
        .then((url) => {
          myResolve(url);
        })
        .catch(async (error) => {
          /* throw error */
          compressFileRef = storage.ref(
            subFolder ? `${folderID}/${fileName}` : `${folderID}/${fileName}`
          );
          //console.log("fileStorageRef 2", `${folderID}/stopMedia/${fileName}`)
          await compressFileRef
            .getDownloadURL()
            .then((url) => {
              myResolve(url);
            })
            .catch(async (error) => {
              compressFileRef = storage.ref(
                subFolder
                  ? `${folderID}/${fileName}.${type}`
                  : `${folderID}/${fileName}.${type}`
              );
              //console.log("fileStorageRef 3", `${folderID}/stopMedia/${fileName}.${type}`)
              await compressFileRef
                .getDownloadURL()
                .then((url) => {
                  myResolve(url);
                })
                .catch((error) => {
                  if (error.code === "storage/object-not-found")
                    myReject("storage/object-not-found");
                });
            });
        });
    } catch (error) {
      console.log("GENERAL ERROR");
      myReject("false");
    }
  });
};

const tagDict = {
  General: "כללי",
  Classic: "קלאסי",
  History: "היסטורי",
  Culture: "תרבות",
  Artistic: "אומנותי",
  Musical: "מוזיקלי",
  Nature: "טבע",
  Culinary: "קולינרי",
  Religious: "דת",
  Food: "אוכל",
  general: "כללי",
  classic: "קלאסי",
  history: "היסטורי",
  culture: "תרבות",
  artistic: "אומנותי",
  musical: "מוזיקלי",
  nature: "טבע",
  culinary: "קולינרי",
  religious: "דת",
  food: "אוכל",
};
const dictByLnag = {
  Hebrew: {
    stop: "עצירה",
  },
  English: {
    stop: "stop",
  },
};

const updateApprovDate = function (tour) {
  tour.approval_date = Date();
  firebase
    .firestore()
    .collection("tours")
    .doc(tour.id)
    .set(tour)
    .then(() => {
      console.log("tour approval_date saved");
    })
    .catch((err) => {
      console.log("tour Approve ERROR!", err);
    });
};

export const approveTour = function (tour) {
  return new Promise(async function (myResolve, myReject) {
    if (tour.stops.length < 2) {
      myReject(
        "Tour most include at least 2 stops.\n Please add stops before tring the demo."
      );
      return;
    }
    if (!tour.title) {
      myReject(
        "Tour most include title.\n Please fill the basic details of that tour."
      );
      return;
    }

    let stopInRoute = 0;
    const MAXSTOPSINROUTE = 70;
    let approve_tour = JSON.parse(JSON.stringify(tour));
    let bigStopCnt = 1;
    try {
      //starting_point
      if (approve_tour.stops[0] && approve_tour.stops[0].stop_location) {
        approve_tour.starting_point = {
          latitude: approve_tour.stops[0].stop_location["latitude"],
          longitude: approve_tour.stops[0].stop_location["longitude"],
        };
      }
      //starting_video
      if (approve_tour.starting_name) {
        fileStorageRef(
          tour.id,
          `${approve_tour.starting_name}_compress`,
          "mp4",
          null
        )
          .then((resp) => {
            approve_tour.starting_video = resp;
            approve_tour.starting_name = `${approve_tour.starting_name}_compress`;
          })
          .catch((error) =>
            console.log(
              "DONT GOT IN FUNCTION",
              `${tour.id} ${media.name}_compress`
            )
          );
      }
      if (approve_tour.tour_guide.profile_image) {
        fileStorageRef(tour.id, `guideProfile_resize`, "jpg", null)
          .then((resp) => {
            approve_tour.tour_guide.profile_image = resp;
          })
          .catch((error) =>
            console.log(
              "DONT GOT IN FUNCTION",
              `${tour.id} guideProfile_resize`
            )
          );
      } else {
        approve_tour.tour_guide.profile_image =
          "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/avatar-blank.png?alt=media&token=d33a1c38-6d60-4aa1-b7fa-9a324f6a03c4";
      }

      //all_media
      approve_tour.all_media.map((media, idx) => {
        if (media.type.includes("video")) {
          fileStorageRef(tour.id, `${media.name}_compress`, "mp4", null)
            .then((resp) => {
              approve_tour.all_media[idx].url = resp;
              approve_tour.all_media[idx].name = `${media.name}_compress`;
            })
            .catch((error) => console.log("DONT GOT IN FUNCTION", error));
        } else if (media.type.includes("image")) {
          fileStorageRef(tour.id, `${media.name}_resize`, "jpg", null)
            .then((resp) => {
              approve_tour.all_media[idx].url = resp;
              approve_tour.all_media[idx].name = `${media.name}_resize`;
            })
            .catch((error) => console.log("DONT GOT IN FUNCTION", error));
        }
      });

      if (approve_tour.language === "Hebrew")
        approve_tour.exp_video =
          "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/maslol_exp_he_compress.mp4?alt=media&token=cb0b7755-be31-4f55-9df6-42158bd4c1b0";
      else
        approve_tour.exp_video =
          "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/mexpo_vid.mp4?alt=media&token=a10b2584-6a4e-4a90-a02c-466ea06d02ad";

      approve_tour.stops.map((stop, idx) => {
        if (stop.type === "parkingStop") {
          approve_tour.parking = {
            latitude: stop.stop_location.latitude,
            longitude: stop.stop_location.longitude,
            name: stop.s_title,
          };
          approve_tour.stops.splice(idx, 1);
          return;
        }

        if (stop.include_path) {
          stopInRoute += 1;
          if (stopInRoute > MAXSTOPSINROUTE) {
            approve_tour.stops[idx].include_path = false;
          }
        }

        if (stop.type === "directionStop") {
          approve_tour.stops[idx].type = "smallStop";
          return;
        }

        if (approve_tour.language === "Hebrew") {
          const tmpTags = approve_tour.stops[idx].tags
            ? approve_tour.stops[idx].tags
            : [];
          approve_tour.stops[idx].tags = [];
          tmpTags.map((tag) => {
            approve_tour.stops[idx].tags.push(tagDict[tag.toLowerCase()]);
          });
        }

        if (stop.type.includes("big")) {
          approve_tour.stops[idx].stop_index = bigStopCnt;
          bigStopCnt++;

          if (stop.direction_text)
            approve_tour.stops[idx].diraction_text = stop.direction_text;

          if (stop.loc_pics && stop.loc_pics[0]) {
            fileStorageRef(
              tour.id,
              `${stop.loc_pics[0].name}_resize`,
              "jpg",
              null
            )
              .then((resp) => {
                approve_tour.stops[idx].loc_pics[0].url = resp;
                approve_tour.stops[
                  idx
                ].loc_pics[0].name = `${stop.loc_pics[0].name}_resize`;
              })
              .catch((error) =>
                console.log(
                  "DONT GOT IN FUNCTION",
                  error,
                  stop.loc_pics[0].name
                )
              );
          } /* (!stop.loc_pics || !stop.loc_pics[0]) */ else {
            approve_tour.stops[idx].loc_pics = [
              {
                name: "direcTmp",
                order: 0,
                title: "title",
                type: "image/jpeg",
                url: "https://firebasestorage.googleapis.com/v0/b/dguidedev.appspot.com/o/direcTmp.jpg?alt=media&token=580c9983-9b39-4b30-a64b-00695c65b8eb",
              },
            ];
          }
        }

        stop.all_media.map(async (media, mIdx) => {
          if (media.type.includes("video")) {
            fileStorageRef(tour.id, `${media.name}_compress`, "mp4", null)
              .then((resp) => {
                approve_tour.stops[idx].all_media[mIdx].url = resp;
                approve_tour.stops[idx].all_media[
                  mIdx
                ].name = `${media.name}_compress`;
              })
              .catch((error) =>
                console.log(
                  "DONT GOT IN FUNCTION",
                  `${tour.id} ${media.name}_compress`
                )
              );
            if (idx === 0 && !approve_tour.starting_video) {
              approve_tour.starting_video =
                approve_tour.stops[idx].all_media[mIdx].url;
              approve_tour.starting_name =
                approve_tour.stops[idx].all_media[mIdx].name;
            }
            /* let compress_name = `${media.name}_compress`
            let compressImageRef = storage.ref(`${tour.id}/${compress_name}`)
            await compressImageRef.getDownloadURL().then(url => {
              approve_tour.stops[idx].all_media[mIdx].url = url
              approve_tour.stops[idx].all_media[mIdx].name =  `${compress_name}`
            }).catch((error) => { console.log("throw error, Can't find a copress file") }) */
          } else if (media.type.includes("image")) {
            fileStorageRef(tour.id, `${media.name}_resize`, "jpg", null)
              .then((resp) => {
                approve_tour.stops[idx].all_media[mIdx].url = resp;
                approve_tour.stops[idx].all_media[
                  mIdx
                ].name = `${media.name}_resize`;
              })
              .catch((error) => console.log("DONT GOT IN FUNCTION", error));
          }
        });
      });
      setTimeout(() => {
        approve_tour.approve_date = Date();
        firebase
          .firestore()
          .collection("approval_saas")
          .doc(tour.id)
          .set(approve_tour)
          .then(() => {
            //updateApprovDate(tour)
            myResolve(approve_tour);
          })
          .catch((err) => {
            console.log("tour Approve ERROR!", err);
            myReject(err);
          });
      }, 9000);
    } catch (error) {
      console.log("ERROR", error);
    }
  });
};

export const approveTourToDguide = function (
  tour,
  saas_approve = false,
  old_system = true
) {
  return new Promise(async function (myResolve, myReject) {
    let approve_tour = JSON.parse(JSON.stringify(tour));
    let bigStopCnt = 1;
    let stopsCnt = 0;
    delete approve_tour["rating"];
    try {
      //all_media
      //editAllMediaUrl(tour.all_media).then(newAllMedia => tour.all_media)

      //starting_video
      const mp4Media = tour.all_media.find((media) =>
        media.type.includes("mp4")
      );
      console.log("mp4Media", mp4Media);
      if (mp4Media) {
        const starting_name = mp4Media.name.includes("compress")
          ? `${mp4Media.name}`
          : `${mp4Media.name}_compress`;
        console.log(
          "LOOKKING FOR ",
          `${tour.id}/toursMedia/${starting_name}.mp4`
        );
        const imageRef = storage.ref(
          `${tour.id}/toursMedia/${starting_name}.mp4`
        );
        await imageRef
          .getDownloadURL()
          .then((url) => {
            approve_tour.starting_video = url;
            approve_tour.starting_name = `${starting_name}`;
            approveTour.starting_type = mp4Media.type;
          })
          .catch((error) => {
            /* throw error */ console.log(
              "throw error, Can't find a copress file for starting",
              error
            );
          });
      }

      approve_tour.all_media.map((media, idx) => {
        if (media.type.includes("video")) {
          if (old_system) {
            fileStorageRef(
              tour.id,
              `${media.name}_compress`,
              "mp4",
              "toursMedia"
            )
              .then((resp) => {
                approve_tour.all_media[idx].url = resp;
                approve_tour.all_media[idx].name = `${media.name}_compress`;
              })
              .catch((error) => console.log("DONT GOT IN FUNCTION all_media"));
          } else {
            fileStorageRefNoSubFolder(tour.id, `${media.name}_compress`, "mp4")
              .then((resp) => {
                approve_tour.all_media[idx].url = resp;
                approve_tour.all_media[idx].name = `${media.name}_compress`;
                approve_tour.all_media[idx].type = "video/mp4";
              })
              .catch((error) => console.log("DONT GOT IN FUNCTION all_media"));
          }
        } else if (media.type.includes("image")) {
          if (old_system) {
            fileStorageRef(tour.id, `${media.name}_resize`, "jpg", "toursMedia")
              .then((resp) => {
                approve_tour.all_media[idx].url = resp;
                approve_tour.all_media[idx].name = `${media.name}_resize`;
              })
              .catch((error) => console.log("DONT GOT IN FUNCTION"));
          } else {
            fileStorageRefNoSubFolder(tour.id, `${media.name}_resize`, "jpg")
              .then((resp) => {
                approve_tour.all_media[idx].url = resp;
                approve_tour.all_media[idx].name = `${media.name}_resize`;
              })
              .catch((error) => console.log("DONT GOT IN FUNCTION"));
          }
        }
      });
      approve_tour.exp_video =
        "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/expo_video.mp4?alt=media&token=1236c5eb-1097-4d48-946e-381b9e10085e";
      approve_tour.stops.map((stop, idx) => {
        if (stop.type === "parkingStop") {
          approve_tour.parking = {
            latitude: stop.stop_location.latitude,
            longitude: stop.stop_location.longitude,
            name: stop.s_title,
          };
          approve_tour.stops.splice(idx, 1);
          return;
        }
        if (stop.type.includes("big")) {
          approve_tour.stops[idx].stop_index = bigStopCnt;
          bigStopCnt++;

          /****** Handle loc_pic ************/
          if (stop.loc_pics && stop.loc_pics[0]) {
            if (old_system) {
              fileStorageRef(stop.id, `${stop.loc_pics[0].name}_resize`, "jpg")
                .then((resp) => {
                  approve_tour.stops[idx].loc_pics[0].url = resp;
                  approve_tour.stops[
                    idx
                  ].loc_pics[0].name = `${stop.loc_pics[0].name}_resize`;
                })
                .catch((error) => console.log("DONT GOT IN FUNCTION", error));
            } else {
              fileStorageRef(
                stop.id,
                `${stop.loc_pics[0].name}_resize`,
                "jpg",
                false
              )
                .then((resp) => {
                  approve_tour.stops[idx].loc_pics[0].url = resp;
                  approve_tour.stops[
                    idx
                  ].loc_pics[0].name = `${stop.loc_pics[0].name}_resize`;
                })
                .catch((error) => console.log("DONT GOT IN FUNCTION", error));
            }
          }

          if (stop.direction_text)
            approve_tour.stops[idx].diraction_text = stop.direction_text;
        }
        if (!stop.loc_pics || !stop.loc_pics[0]) {
          approve_tour.stops[idx].loc_pics = [
            {
              name: "direcTmp",
              order: 0,
              title: "title",
              type: "image/jpeg",
              url: "https://firebasestorage.googleapis.com/v0/b/dguidedev.appspot.com/o/direcTmp.jpg?alt=media&token=580c9983-9b39-4b30-a64b-00695c65b8eb",
            },
          ];
        }
        approve_tour.stops[idx].display_icon = stop.show_marker
          ? stop.show_marker
          : true;
        approve_tour.stops[idx].include_path = stop.include_path
          ? stop.include_path
          : true;
        if (approve_tour.stops[idx].direction_type === undefined)
          console.log("DIRECTION TYPE SET TO FOOT", stop.direction_type);
        if (approve_tour.stops[idx].direction_type === undefined)
          approve_tour.stops[idx].direction_type = "foot";
        stop.all_media.map(async (media, mIdx) => {
          try {
            if (media.type.includes("video")) {
              try {
                if (old_system) {
                  fileStorageRef(
                    stop.id,
                    media.name.includes("compress")
                      ? `${media.name}plus`
                      : `${media.name}_compressplus`,
                    "mp4",
                    "stopMedia"
                  )
                    .then((resp) => {
                      approve_tour.stops[idx].all_media[mIdx].url = resp;
                      approve_tour.stops[idx].all_media[mIdx].name =
                        media.name.includes("compress")
                          ? `${media.name}plus`
                          : `${media.name}_compressplus`;
                    })
                    .catch((error) => {
                      fileStorageRef(
                        stop.id,
                        media.name.includes("compress")
                          ? media.name
                          : `${media.name}_compress`,
                        "mp4",
                        "stopMedia"
                      )
                        .then((resp) => {
                          approve_tour.stops[idx].all_media[mIdx].url = resp;
                          approve_tour.stops[idx].all_media[mIdx].name =
                            media.name.includes("compress")
                              ? media.name
                              : `${media.name}_compress`;
                        })
                        .catch((error) => {
                          console.log("DONT GOT IN FUNCTION", error);
                        });
                    });
                  /* fileStorageRef(stop.id, `${media.poster_name}_resize`, "jpg").then(resp => {
                  approve_tour.stops[idx].all_media[mIdx].poster_url = resp
                  approve_tour.stops[idx].all_media[mIdx].poster_name =  `${media.poster_name}_resize`
                }).catch((error) => console.log("DONT GOT IN FUNCTION", error)) */
                } else {
                  fileStorageRef(
                    tour.id,
                    `${media.name}_compress`,
                    "mp4",
                    false
                  )
                    .then((resp) => {
                      approve_tour.stops[idx].all_media[mIdx].url = resp;
                      approve_tour.stops[idx].all_media[
                        mIdx
                      ].name = `${media.name}_compress`;
                      approve_tour.stops[idx].all_media[mIdx].type =
                        "video/mp4";
                    })
                    .catch((error) =>
                      console.log("DONT GOT IN FUNCTION", error)
                    );
                }
              } catch (error) {
                console.log("GENERAL ERROR");
              }
            } else if (media.type.includes("image")) {
              if (old_system) {
                fileStorageRef(
                  stop.id,
                  `${media.name}_resize`,
                  "jpg",
                  "stopMedia"
                )
                  .then((resp) => {
                    approve_tour.stops[idx].all_media[mIdx].url = resp;
                    approve_tour.stops[idx].all_media[
                      mIdx
                    ].name = `${media.name}_resize`;
                  })
                  .catch((error) => {
                    console.log(
                      "!!!!!!!!throw error, Can't find a resize file",
                      media.name
                    );
                  });
              } else {
                fileStorageRef(tour.id, `${media.name}_resize`, "jpg", false)
                  .then((resp) => {
                    approve_tour.stops[idx].all_media[mIdx].url = resp;
                    approve_tour.stops[idx].all_media[
                      mIdx
                    ].name = `${media.name}_resize`;
                  })
                  .catch((error) => {
                    console.log(
                      "!!!!!!!!throw error, Can't find a resize file",
                      media.name
                    );
                  });
              }
              //let compress_name = `${media.name}_resize`
              //let compressImageRef = storage.ref(`${tour.id}/${compress_name}`)
              //await compressImageRef.getDownloadURL().then(url => {
              //approve_tour.stops[idx].all_media[mIdx].url = url
              //approve_tour.stops[idx].all_media[mIdx].name =  `${compress_name}`
              //}).catch((error) => { console.log("!!!!!!!!throw error, Can't find a resize file", media.name) })
            }
          } catch (error) {
            console.log("catch error", stop.id, stop.order, media, error);
          }
        });
      });
      approve_tour.approval_time = new Date().toLocaleString();
      setTimeout(() => {
        if (saas_approve) {
          console.log("BEFORE APPROVE to SAAS", approve_tour);

          approve_tour.approve_date = Date();
          firebase
            .firestore()
            .collection("approval_saas")
            .doc(tour.id)
            .set(approve_tour /* , {merge: true} */)
            .then(() => {
              //updateApprovDate(tour)
              myResolve(approve_tour);
            })
            .catch((err) => {
              console.log("tour Approve ERROR!", err);
              myReject(err);
            });
        } else {
          console.log("approve firestore to Dguide APP", approve_tour);
          return;
          firebase
            .firestore()
            .collection("approval_tours")
            .doc(tour.id)
            .set(approve_tour, { merge: true })
            .then(() => {
              myResolve(approve_tour);
            })
            .catch((err) => {
              console.log("tour Approve ERROR!", err);
              myReject(err);
            });
        }

        /* firebase.firestore().collection("approval_tours").doc(`${tour.id}`).get().then((snapshot) => {
          const orig_tour = snapshot.data()
          console.log("APPROVE OUTPUT", approve_tour)
          console.log("orig_tour", orig_tour)
          const all_diff = Object.fromEntries(Object.entries(approve_tour).filter(([key, val]) => key in orig_tour && orig_tour[key] !== val))
          console.log("DIFF", all_diff)
        }).catch((e) => {
          console.log(e)
          myReject(null)
        }) */
      }, 30000);
    } catch (error) {
      console.log("ERROR", error);
    }
  });
};

//////////////////// DUPLICATE TOUR ////////////////////////////

export const copyFolderFiles = async (source, destination) => {
  try {
    console.log("copy start");
    const sourceDirectory = `${source}`;
    const destinationDirectory = `${destination}`;
    console.log("sourceDirectory", sourceDirectory);
    // Get a reference to the source directory
    const sourceRef = storage.ref(sourceDirectory);
    console.log("sourceRef", sourceRef);
    // Get a list of files in the source directory
    const files = await sourceRef.listAll();
    console.log("all  files", files);
    // Iterate through the files and copy them to the destination directory

    await Promise.all(
      files.items.map(async (file) => {
        const fileData = await file.getDownloadURL();
        const destinationPath = `${destinationDirectory}${file.name}`;
        const destinationRef = storage.ref().child(destinationPath);

        // Upload the file to the destination location
        await fetch(fileData)
          .then((response) => response.blob())
          .then((blob) => destinationRef.put(blob));
      })
    );

    console.log("Files copied successfully.");
  } catch (error) {
    console.log("CATCH ERROR", error);
  }
};

export const fixCopy = async () => {
  await copyFolderFiles(
    `0a2de5a3ffef41ea88f98f57d7d894a5/`,
    `cb61010304b442958130b5cd74798438/`
  );
  console.log("AFTER COPY");
  //await copyFolderFiles(`0a2de5a3ffef41ea88f98f57d7d894a5/`, `0a2de5a3ffef41ea88f98f57d7d894a5/`);
};

export const dupTour = function (tour, with_media = false) {
  return new Promise(async function (myResolve, myReject) {
    try {
      const tId = getUUID();
      console.log("new tour ID", tId);
      let dup_tour = JSON.parse(JSON.stringify(tour));
      dup_tour.id = tId;
      dup_tour.title = `${tour.title}-DUPLICATED`;
      //for JapanTuri
      dup_tour.language = `English`;
      if (!with_media) {
        dup_tour.stops.map((stop, s_idx) => {
          dup_tour.stops[s_idx].all_media = [];
          dup_tour.stops[s_idx].loc_pics = [];
        });

        //dup_tour.all_media = stop.all_media.filter(media => media.type.includes('video') || media.type.includes('audio') || media.type.includes('pic') )
        dup_tour.starting_video = "";
        dup_tour.starting_name = "";
        dup_tour.all_media = [];
        dup_tour.tour_image = "";
        dup_tour.tour_image_obj = {};
        dup_tour.tour_guide.profile_image = "";
      } else {
        console.log(
          "starting copy files",
          `from ${tour.id}/ to: ${dup_tour.id}/`
        );
        await copyFolderFiles(`${tour.id}/`, `${dup_tour.id}/`);
        console.log("after copy files");

        if (tour.all_media) {
          tour.all_media.map(async (media, m_idx) => {
            if (media.url)
              await storage
                .ref(`${dup_tour.id}/${media.name}`)
                .getDownloadURL()
                .then((url) => (dup_tour.all_media[m_idx].url = url))
                .catch((err) => {
                  dup_tour.all_media[m_idx].url = "";
                });
          });
        }

        if (dup_tour.stops)
          dup_tour.stops.map(async (stop, s_idx) => {
            if (stop.all_media)
              stop.all_media.map(async (media, mediaIdx) => {
                if (media.url) {
                  console.log(`ref media ${dup_tour.id}/${media.name}`);
                  await storage
                    .ref(`${dup_tour.id}/${media.name}`)
                    .getDownloadURL()
                    .then(
                      (url) =>
                        (dup_tour.stops[s_idx].all_media[mediaIdx].url = url)
                    )
                    .catch((err) => {
                      dup_tour.stops[s_idx].all_media[mediaIdx].url = "";
                    });
                }
              }); //all_media loop

            if (stop.loc_pics && stop.loc_pics[0]) {
              console.log(
                `ref loc_pic ${dup_tour.id}/${stop.loc_pics[0].name}`
              );
              await storage
                .ref(`${dup_tour.id}/${stop.loc_pics[0].name}`)
                .getDownloadURL()
                .then((url) => (dup_tour.stops[s_idx].loc_pics[0].url = url))
                .catch((err) => (dup_tour.stops[s_idx].loc_pics[0].url = ""));
            }
          }); //stops loop

        if (dup_tour.starting_name) {
          console.log(
            `ref tour_image_obj ${dup_tour.id}/${dup_tour.starting_name}`
          );
          await storage
            .ref(`${dup_tour.id}/${dup_tour.starting_name}`)
            .getDownloadURL()
            .then((url) => (dup_tour.starting_video = url))
            .catch((err) => (dup_tour.starting_video = ""));
        }

        if (dup_tour.tour_image_obj && dup_tour.tour_image_obj.name) {
          console.log(
            `ref starting_name ${dup_tour.id}/${dup_tour.tour_image_obj.name}`
          );
          await storage
            .ref(`${dup_tour.id}/${dup_tour.tour_image_obj.name}`)
            .getDownloadURL()
            .then((url) => {
              dup_tour.tour_image_obj.url = url;
              dup_tour.tour_image = url;
            })
            .catch((err) => (dup_tour.tour_image_obj.url = ""));
        }
        dup_tour.tour_guide.profile_image = "";
      }
      console.log("dup_tour", dup_tour);
      console.log("tour", tour);
      setTimeout(() => {
        firebase
          .firestore()
          .collection("tours")
          .doc(dup_tour.id)
          .set(dup_tour, { merge: true })
          .then(() => {
            myResolve(dup_tour);
          })
          .catch((err) => {
            console.log("tour Approve ERROR!", err);
            myReject(err);
          });
      }, 2000);
      //response.then(resp => console.log("RESP", resp))
    } catch (error) {
      console.log("THRER IS AN ERROR", error);
    }
  });
};

export const getFileSize = function (tourID) {
  return new Promise(async function (myResolve, myReject) {
    try {
      let sumVidSize = 0;
      let sumImgSize = 0;
      //https://europe-west6-tours-app-1579553856346.cloudfunctions.net/getAllAss
      console.log(
        "start",
        `https://europe-west6-tours-app-1579553856346.cloudfunctions.net/getAllAss?tourID=${tourID}`
      );
      fetch(
        `https://europe-west6-tours-app-1579553856346.cloudfunctions.net/getAllAss?tourID=${tourID}`,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
        .then((response) => {
          console.log("response", response);
          return response.json();
        })
        .then((data) => {
          console.log(data);
          data.map((url) => {
            try {
              let fileRef = ref(storage, url);
              getMetadata(fileRef)
                .then((metadata) => {
                  // Metadata now contains the metadata for 'images/forest.jpg'

                  if (metadata.contentType.includes("video")) {
                    sumVidSize += metadata.size / 1048576;
                    console.log("Video", url, metadata.size / 1048576);
                  } else {
                    sumImgSize += metadata.size / 1048576;
                    console.log("img", url, metadata.size / 1048576);
                  }
                  console.log(
                    url,
                    "sumImgSize",
                    sumImgSize,
                    "sumVidSize",
                    sumVidSize
                  );
                })
                .catch((error) => {
                  // Uh-oh, an error occurred!
                  console.log("metadata", error, url);
                });
            } catch (err) {
              console.log("ref", err, url);
            }
          });
        })
        .catch((err) => {
          console.log("err in json()", err);
        });

      // const response = await fetch('https://api.npms.io/v2/search?q=react');
      //const data = await response.json();
      //this.setState({ totalReactPackages: data.total })
    } catch (rrror) {
      console.log("THRER IS AN ERROR", error);
    }
  });
};

const getFileStorageUrl = function (
  folderID,
  secondFolderID,
  fileName,
  type,
  subFolder
) {
  return new Promise(async function (myResolve, myReject) {
    try {
      //check the file in tourID with type of the file
      let compressFileRef = storage.ref(`${folderID}/${fileName}.${type}`);
      //console.log("TRYING", `${folderID}/${fileName}.${type}`, compressFileRef)
      console.log("TRYING", `${folderID}/${fileName}`, compressFileRef);
      await compressFileRef
        .getDownloadURL()
        .then((url) => {
          myResolve(url);
        })
        .catch(async (error) => {
          compressFileRef = storage.ref(`${folderID}/${fileName}`);
          await compressFileRef
            .getDownloadURL()
            .then((url) => {
              myResolve(url);
            })
            .catch(async (error) => {
              let compressFileRef = storage.ref(
                `${folderID}/${subFolder}/${fileName}.${type}`
              );
              console.log(
                "TRYING",
                `${folderID}/${subFolder}/${fileName}.${type}`,
                compressFileRef
              );
              await compressFileRef
                .getDownloadURL()
                .then((url) => {
                  myResolve(url);
                })
                .catch(async (error) => {
                  if (secondFolderID === null) {
                    if (error.code === "storage/object-not-found")
                      myReject("storage/object-not-found");
                    else myReject("false");
                  }
                  compressFileRef = storage.ref(
                    `${secondFolderID}/${subFolder}/${fileName}.${type}`
                  );
                  await compressFileRef
                    .getDownloadURL()
                    .then((url) => {
                      myResolve(url);
                    })
                    .catch(async (error) => {
                      compressFileRef = storage.ref(
                        `${secondFolderID}/${subFolder}/${fileName}`
                      );
                      await compressFileRef
                        .getDownloadURL()
                        .then((url) => {
                          myResolve(url);
                        })
                        .catch(async (error) => {
                          if (error.code === "storage/object-not-found")
                            myReject("storage/object-not-found");
                        });
                    });
                });
            });
        });
    } catch (error) {
      console.log("GENERAL ERROR", error);
      myReject("false");
    }
  });
};

export const promiseApproveTour = function (tour, better_quality = false) {
  return new Promise(async function (myResolve, myReject) {
    if (tour.stops.length < 2) {
      myReject(
        "Tour must include at least 2 stops.\n Please add stops before trying the demo."
      );
      return;
    }
    if (!tour.title) {
      myReject(
        "Tour must include a title.\n Please fill in the basic details of the tour."
      );
      return;
    }

    let stopInRoute = 0;
    const MAXSTOPSINROUTE = 70;
    let approve_tour = JSON.parse(JSON.stringify(tour));
    let bigStopCnt = 1;

    try {
      // Starting point
      if (approve_tour.stops[0] && approve_tour.stops[0].stop_location) {
        approve_tour.starting_point = {
          latitude: approve_tour.stops[0].stop_location["latitude"],
          longitude: approve_tour.stops[0].stop_location["longitude"],
        };
      }
      if (!approve_tour.language) approve_tour.language = "English";
      // Starting video or picture
      console.log(
        "starting_name",
        approve_tour.starting_name,
        approve_tour.starting_type
      );
      let startingMediaPromise;
      if (approve_tour.starting_name || approve_tour.starting_type) {
        if (approve_tour?.starting_type.includes("image")) {
          console.log("starting_name", approve_tour.starting_name);
          startingMediaPromise = getFileStorageUrl(
            tour.id,
            null,
            `${approve_tour?.starting_name}_resize`,
            "jpg",
            null
          )
            .then((resp) => {
              approve_tour.starting_image = resp;
              approve_tour.starting_name = `${approve_tour?.starting_name}_resize`;
            })
            .catch((error) => {
              console.log(
                "DID NOT GET FUNCTION",
                `${tour.id} starting_name_resize`,
                error
              );
              approve_tour.starting_image = tour.starting_url;
            });
        } else {
          startingMediaPromise = getFileStorageUrl(
            tour.id,
            approve_tour.stops[0].id,
            `${approve_tour.starting_name}_compress`,
            "mp4",
            "toursMedia"
          )
            .then((resp) => {
              approve_tour.starting_video = resp;
              approve_tour.starting_name = `${approve_tour.starting_name}_compress`;
            })
            .catch((error) =>
              console.log(
                "DID NOT GET FUNCTION",
                `${tour.id} ${approve_tour.starting_name}_compress`,
                error
              )
            );
        }
      } else {
        approve_tour.starting_video = null;
        approve_tour.starting_name = null;
        startingMediaPromise = Promise.resolve();
      }

      let profileImagePromise;
      if (
        approve_tour.tour_guide.profile_image &&
        !approve_tour.tour_guide.profile_image.includes("lh3")
      ) {
        profileImagePromise = getFileStorageUrl(
          tour.id,
          null,
          `guideProfile_resize`,
          "jpg",
          null
        )
          .then((resp) => {
            approve_tour.tour_guide.profile_image = resp;
          })
          .catch((error) =>
            console.log(
              "DID NOT GET FUNCTION",
              `${tour.id} guideProfile_resize`,
              error
            )
          );
      } else {
        approve_tour.tour_guide.profile_image =
          "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/avatar-blank.png?alt=media&token=d33a1c38-6d60-4aa1-b7fa-9a324f6a03c4";
        profileImagePromise = Promise.resolve();
      }

      // Processing all_media
      const mediaPromises = approve_tour.all_media.map((media, idx) => {
        console.log(
          `Processing media item ${idx + 1} of ${approve_tour.all_media.length}`
        );
        if (media.type.includes("video")) {
          return getFileStorageUrl(
            tour.id,
            null,
            `${media.name}_compress`,
            "mp4",
            "toursMedia"
          )
            .then((resp) => {
              approve_tour.all_media[idx].url = resp;
              approve_tour.all_media[idx].name = `${media.name}_compress`;
            })
            .catch((error) => console.log("DID NOT GET FUNCTION", error));
        } else if (media.type.includes("image")) {
          return getFileStorageUrl(
            tour.id,
            null,
            `${media.name}_resize`,
            "jpg",
            "toursMedia"
          )
            .then((resp) => {
              approve_tour.all_media[idx].url = resp;
              approve_tour.all_media[idx].name = `${media.name}_resize`;
            })
            .catch((error) => console.log("DID NOT GET FUNCTION", error));
        } else {
          return Promise.resolve();
        }
      });

      if (approve_tour.language && approve_tour.language.includes("Hebrew")) {
        approve_tour.exp_video =
          "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/maslol_exp_he_compress.mp4?alt=media&token=cb0b7755-be31-4f55-9df6-42158bd4c1b0";
      } else {
        approve_tour.exp_video =
          "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/mexpo_vid.mp4?alt=media&token=a10b2584-6a4e-4a90-a02c-466ea06d02ad";
      }

      // Iterate over stops and insert new stop if direction_type changes
      let newStops = [];
      let previousStop = approve_tour.stops[0];
      for (let i = 0; i < approve_tour.stops.length; i++) {
        if (
          !approve_tour.stops[i].include_path ||
          !approve_tour.stops[i].direction_type
        ) {
          newStops.push(approve_tour.stops[i]);
          continue;
        }

        const currentStop = approve_tour.stops[i];

        if (i > 0) {
          if (currentStop.direction_type === "none") {
            const newStop = {
              direction_type: "none",
              stop_location: currentStop.stop_location,
              include_path: true,
              show_marker: false,
              order: i,
              show_on_slider: false,
              s_title: `Duplicate Stop ${i}`,
            };
            currentStop.direction_type = approve_tour?.stops[i + 1]
              ?.direction_type
              ? approve_tour.stops[i + 1].direction_type
              : "foot";
            newStops.push(newStop);
          } else if (
            previousStop.direction_type !== currentStop.direction_type
          ) {
            const newStop = {
              direction_type: currentStop.direction_type,
              stop_location: previousStop.stop_location,
              include_path: true,
              show_marker: false,
              order: newStops.length,
              show_on_slider: false,
              s_title: `Duplicate Stop ${i}`,
            };

            newStops.push(newStop);
          }
        } else {
          currentStop.direction_type = approve_tour.stops[1].direction_type
            ? approve_tour.stops[1].direction_type
            : "foot";
        }
        currentStop.order = newStops.length;
        newStops.push(currentStop);
        previousStop = approve_tour.stops[i];
      }

      approve_tour.stops = newStops;
      let sliderItemCounter = 0;
      const stopPromises = approve_tour.stops.map(async (stop, idx) => {
        // Ensure all_media is an array, even if it's initially undefined
        approve_tour.stops[idx].all_media =
          approve_tour.stops[idx].all_media || [];

        /*  if (stop.show_marker === true) {
          if (stop.slider_item_title) {
            approve_tour.stops[
              idx
            ].slider_item_title = `${stop.slider_item_title}`;
          }
        } */
        // Ensure stop.all_media is an array
        if (!Array.isArray(stop.all_media)) {
          stop.all_media = [];
        }

        if (stop.icon === undefined || stop.show_marker === false) {
          delete approve_tour.stops[idx].icon;
        } else if (stop.icon && stop.icon.name !== "none") {
          if (stop.slider_item_title) {
            approve_tour.stops[
              idx
            ].slider_item_title = `${stop.slider_item_title}`;
          } else {
            approve_tour.stops[idx].slider_item_title = `${stop.icon.label} ${
              dictByLnag[approve_tour.language].stop
            }`;
          }
          approve_tour.stops[idx].icon = stop.icon.name;
        } else if (!stop.icon || (stop.icon && stop.icon.name === "none")) {
          delete approve_tour.stops[idx].icon;
          approve_tour.stops[idx].stop_index = bigStopCnt;
          bigStopCnt++;
        }

        if (stop.type === "parkingStop") {
          approve_tour.parking = {
            latitude: stop.stop_location.latitude,
            longitude: stop.stop_location.longitude,
            name: stop.s_title,
          };
          approve_tour.stops.splice(idx, 1);
          return;
        }

        if (!stop.show_marker && idx !== 0) {
          approve_tour.stops[idx].display_icon = false;
          approve_tour.stops[idx].type = "smallStop";
          return;
        }

        if (stop.include_path) {
          stopInRoute += 1;
          if (stopInRoute > MAXSTOPSINROUTE) {
            approve_tour.stops[idx].include_path = false;
          }
        }

        if (stop.type === "directionStop") {
          approve_tour.stops[idx].type = "smallStop";
          return;
        }

        if (approve_tour.language === "Hebrew") {
          const tmpTags = approve_tour.stops[idx].tags
            ? approve_tour.stops[idx].tags
            : [];
          approve_tour.stops[idx].tags = [];
          tmpTags.map((tag) => {
            const tagValue =
              tagDict[tag.toLowerCase()] !== undefined
                ? tagDict[tag.toLowerCase()]
                : tag;
            approve_tour.stops[idx].tags.push(tagValue);
          });
        }

        if (idx === 0 && (!stop.loc_pics || !stop.loc_pics[0])) {
          approve_tour.stops[idx].loc_pics = [
            {
              name: "direcTmp",
              order: 0,
              title: "title",
              type: "image/jpeg",
              url: "https://firebasestorage.googleapis.com/v0/b/tours-app-1579553856346.appspot.com/o/0directionPic.png?alt=media&token=9ab51616-61b3-42b9-a7e3-8e3261c6abd1",
            },
          ];
        }

        if (stop.type.includes("big")) {
          approve_tour.stops[idx].show_on_slider = true;
          if (stop.direction_text)
            approve_tour.stops[idx].diraction_text = stop.direction_text;

          let locPicsPromise;
          if (stop.loc_pics && stop.loc_pics[0]) {
            locPicsPromise = getFileStorageUrl(
              tour.id,
              stop.id,
              `${stop.loc_pics[0].name}_resize`,
              "jpg",
              "stopMedia"
            )
              .then((resp) => {
                approve_tour.stops[idx].loc_pics[0].url = resp;
                approve_tour.stops[
                  idx
                ].loc_pics[0].name = `${stop.loc_pics[0].name}_resize`;
              })
              .catch((error) =>
                console.log(
                  "DID NOT GET FUNCTION",
                  error,
                  stop.loc_pics[0].name
                )
              );
          } else {
            locPicsPromise = Promise.resolve();
          }

          await locPicsPromise;
        } else {
          console.log("THIS IS NOT BIG STOP", stop.loc_pics);
          let locPicsPromise;
          if (stop.loc_pics && stop.loc_pics[0]) {
            approve_tour.stops[idx].show_on_slider = true;
            locPicsPromise = getFileStorageUrl(
              tour.id,
              stop.id,
              `${stop.loc_pics[0].name}_resize`,
              "jpg",
              "stopMedia"
            )
              .then((resp) => {
                approve_tour.stops[idx].loc_pics[0].url = resp;
                approve_tour.stops[
                  idx
                ].loc_pics[0].name = `${stop.loc_pics[0].name}_resize`;
              })
              .catch((error) =>
                console.log(
                  "DID NOT GET FUNCTION",
                  error,
                  stop.loc_pics[0].name
                )
              );
          } else {
            locPicsPromise = Promise.resolve();
          }

          await locPicsPromise;
        }

        const mediaPromises = stop.all_media.map((media, mIdx) => {
          try {
            if (!media.type) {
              approve_tour.stops[idx].all_media.splice(mIdx, 1);
            }
            if (media.type.includes("video")) {
              if (!better_quality) {
                return getFileStorageUrl(
                  tour.id,
                  stop.id,
                  `${media.name}_compressplus`,
                  "mp4",
                  "stopMedia"
                )
                  .then((resp) => {
                    approve_tour.stops[idx].all_media[mIdx].url = resp;
                    approve_tour.stops[idx].all_media[
                      mIdx
                    ].name = `${media.name}_compressplus`;
                  })
                  .catch((error) => {
                    return getFileStorageUrl(
                      tour.id,
                      stop.id,
                      `${media.name}_compress`,
                      "mp4",
                      "stopMedia"
                    )
                      .then((resp) => {
                        approve_tour.stops[idx].all_media[mIdx].url = resp;
                        approve_tour.stops[idx].all_media[
                          mIdx
                        ].name = `${media.name}_compress`;
                      })
                      .catch((error) =>
                        console.log(
                          "DID NOT GET FUNCTION",
                          `${tour.id} ${media.name}_compress`,
                          error
                        )
                      );
                  });
              }
            } else if (media.type.includes("image")) {
              return getFileStorageUrl(
                tour.id,
                stop.id,
                `${media.name}_resize`,
                "jpg",
                "stopMedia"
              )
                .then((resp) => {
                  approve_tour.stops[idx].all_media[mIdx].url = resp;
                  approve_tour.stops[idx].all_media[
                    mIdx
                  ].name = `${media.name}_resize`;
                })
                .catch((error) => console.log("DID NOT GET FUNCTION", error));
            } else if (media.type.includes("html")) {
              let tmpContent = approve_tour.stops[idx].all_media[mIdx].content;

              // Replace </em>" with </u>
              tmpContent = tmpContent.replace(/<\/em>"/g, "</u>");

              // Add <br> between </p><p>
              tmpContent = tmpContent.replace(/<\/p>\s*<p>/g, "</p><br><p>");

              // Replace span style
              tmpContent = tmpContent.replace(
                /<span style="color: rgb\(0,0,0\);"/g,
                '<span style="color: rgb(0, 102, 204);"'
              );

              approve_tour.stops[idx].all_media[mIdx].content = tmpContent;
              /*  approve_tour.stops[idx].all_media[mIdx].content =
                tmpContent.replace(/<\/p><p>/g, "</p><br><p>"); */

              return Promise.resolve();
            } else if (media.type.includes("audio")) {
              approve_tour.stops[idx].all_media[mIdx].type = "audio/mpeg";
              return Promise.resolve();
            } else {
              return Promise.resolve();
            }
          } catch (error) {
            console.log(
              "error in stop.all_media",
              error,
              "stop idx",
              idx,
              media,
              mIdx
            );
            approve_tour.stops[idx].all_media.splice(mIdx, 1);
            return Promise.resolve();
          }
        });

        return Promise.all(mediaPromises);
      });

      console.log("mediaPromises", mediaPromises);
      await Promise.all([
        startingMediaPromise,
        profileImagePromise,
        ...mediaPromises,
        ...stopPromises,
      ]);

      approve_tour.approve_date = Date();
      console.log("# new 'approval_saas'", approve_tour);
      firebase
        .firestore()
        .collection("approval_saas")
        .doc(tour.id)
        .set(approve_tour)
        .then(() => {
          myResolve(approve_tour);
        })
        .catch((err) => {
          console.log("tour Approve ERROR!", err);
          myReject(err);
        });
    } catch (error) {
      console.log("ERROR", error);
      myReject(error);
    }
  });
};

export const promiseApproveTourNoMedia = function (tour) {
  return new Promise(async function (myResolve, myReject) {
    console.log("promiseApproveTourNoMedia");
    if (tour.stops.length < 2) {
      myReject(
        "Tour most include at least 2 stops.\n Please add stops before tring the demo."
      );
      return;
    }
    if (!tour.title) {
      myReject(
        "Tour most include title.\n Please fill the basic details of that tour."
      );
      return;
    }

    let stopInRoute = 0;
    const MAXSTOPSINROUTE = 70;
    let approve_tour = JSON.parse(JSON.stringify(tour));
    let bigStopCnt = 1;

    try {
      //starting_point
      if (approve_tour.stops[0] && approve_tour.stops[0].stop_location) {
        approve_tour.starting_point = {
          latitude: approve_tour.stops[0].stop_location["latitude"],
          longitude: approve_tour.stops[0].stop_location["longitude"],
        };
      }

      if (approve_tour.tour_image) approve_tour.tour_image = "";
      //starting_video
      if (approve_tour.starting_name) {
        approve_tour.starting_video = "";
      }
      if (
        approve_tour.tour_guide.profile_image &&
        !approve_tour.tour_guide.profile_image.includes("lh3")
      ) {
        approve_tour.tour_guide.profile_image = "";
      }

      if (approve_tour.tour_image_obj.url) approve_tour.tour_image_obj.url = "";
      //all_media
      approve_tour.all_media = [];
      approve_tour.id = "NoMediaTour";
      approve_tour.stops.map((stop, idx) => {
        if (stop.type === "parkingStop") {
          approve_tour.parking = {
            latitude: stop.stop_location.latitude,
            longitude: stop.stop_location.longitude,
            name: stop.s_title,
          };
          approve_tour.stops.splice(idx, 1);
          return;
        }

        if (stop.include_path) {
          stopInRoute += 1;
          if (stopInRoute > MAXSTOPSINROUTE) {
            approve_tour.stops[idx].include_path = false;
          }
        }

        if (stop.type === "directionStop") {
          approve_tour.stops[idx].type = "smallStop";
          return;
        }

        if (approve_tour.language === "Hebrew") {
          const tmpTags = approve_tour.stops[idx].tags
            ? approve_tour.stops[idx].tags
            : [];
          approve_tour.stops[idx].tags = [];
          tmpTags.map((tag) => {
            approve_tour.stops[idx].tags.push(tagDict[tag.toLowerCase()]);
          });
        }

        approve_tour.stops[idx].loc_pics = [];

        stop.all_media = [];
        if (idx === 0) {
          approve_tour.stops[idx].loc_pics = [
            {
              name: "direcTmp",
              order: 0,
              title: "title",
              type: "image/jpeg",
              url: "https://firebasestorage.googleapis.com/v0/b/dguidedev.appspot.com/o/direcTmp.jpg?alt=media&token=580c9983-9b39-4b30-a64b-00695c65b8eb",
            },
          ];
        }
      });
      setTimeout(() => {
        approve_tour.approve_date = Date();
        firebase
          .firestore()
          .collection("approval_saas")
          .doc("NoMediaTour")
          .set(approve_tour)
          .then(() => {
            //updateApprovDate(tour)
            myResolve(approve_tour);
          })
          .catch((err) => {
            console.log("tour Approve ERROR!", err);
            myReject(err);
          });
      }, 9000);
    } catch (error) {
      console.log("ERROR", error);
    }
  });
};

const openAIcall = function (massage, fromLang, toLang) {
  return new Promise(async function (myResolve, myReject) {
    const key = "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";
    const apiRes = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [
          { role: "user", content: `translate to ${toLang} : ${massage}` },
        ],
      }),
      // body: JSON.stringify({
      //   model: "gpt-3.5-turbo",
      //   messages: [
      //     { role: "system", content: "You are a great German teacher." },
      //     {
      //       role: "assistant",
      //       content:
      //         "Yes, I am. I will translate your German sentence and explain the detail about the translated sentence. ",
      //     },
      //     { role: "user", content },
      //   ],
      // }),
    });

    if (apiRes.status !== 200) {
      console.log(`open ai API status code: ${apiRes.status}`);
      myReject(apiRes.status);
    }

    const json = await apiRes.json();

    console.log({
      message: !json.choices?.length ? "" : json.choices[0].message.content,
    });
    myResolve(!json.choices?.length ? "" : json.choices[0].message.content);
  });
};

export const openAILivecall = function (massage) {
  return new Promise(async function (myResolve, myReject) {
    const key = "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";
    const apiRes = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
      body: JSON.stringify({
        model: "gpt-3.5-turbo",
        messages: [{ role: "user", content: `${massage}` }],
      }),
    });

    if (apiRes.status !== 200) {
      console.log(`open ai API status code: ${apiRes.status}`);
      myReject(apiRes.status);
    }

    const json = await apiRes.json();

    console.log({
      message: !json.choices?.length ? "" : json.choices[0].message.content,
    });
    myResolve(!json.choices?.length ? "" : json.choices[0].message.content);
  });
};

export const GetContentByLocation = function (massage) {
  return new Promise(async function (myResolve, myReject) {
    const key = "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";
    console.log("SEND TO chatGPT");
    const apiRes = await fetch("https://api.openai.com/v1/chat/completions", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${key}`,
      },
      body: JSON.stringify({
        model: "gpt-4",
        messages: [{ role: "user", content: `${massage}` }],
      }),
    });

    if (apiRes.status !== 200) {
      console.log(`open ai API status code: ${apiRes.status}`);
      myReject(apiRes.status);
    }

    const json = await apiRes.json();
    console.log("Got response");
    console.log({
      message: !json.choices?.length ? "0" : json.choices[0].message.content,
    });
    myResolve(!json.choices?.length ? "0" : json.choices[0].message.content);
  });
};

export const createSellingWeb = function (tour) {
  return new Promise(async function (myResolve, myReject) {
    const docRef = firebase.firestore().collection("web_tour").doc(tour.id);

    docRef
      .get()
      .then((doc) => {
        if (!doc.exists) {
          const sellingWeb = {
            id: tour.id,
            title: tour.title,
            tourPriceP: "75 per phone, 3 and more phones in 60 per phone",
            tourTimeInfoP: `The tour is suitable for the hours 8:00-20:00 and it's about ${tour.duration} hours trip`,
            tourTypeP: `${tour.type.join(", ")} tour`,
            tourdescription:
              "Join our exciting tour and discover amazing things! Explore interesting places, learn cool facts, and find surprises around every corner. From old places to new discoveries, every step is full of fun. Come along and see for yourself, it's an adventure you won't forget!",
            tourLocactionP: `Our tour will start in ${tour.location} and will end in ${tour.location}`,
            price: "35",
            color: "green",
            guideProfileImgInput: [""],
            tourImageInput: [""],
            tourImagesArray: [""],
            tourAgeGroupP: "All ages",
            tourVidInput: [""],
          };
          console.log("web_tour", web_tour);
          docRef
            .set(web_tour, { merge: true })
            .then(() => {
              myResolve(tour.id);
            })
            .catch((err) => {
              console.log("tour Approve ERROR!", err);
              myReject(err);
            });
        } else {
          console.log("Document already exists!");
          myResolve(tour.id);
        }
      })
      .catch((err) => {
        console.log("Error getting document:", err);
        myReject(err);
      });
  });
};

export const translateTour = function (tour, fromLang, toLang) {
  return new Promise(async function (myResolve, myReject) {
    let translate_tour = JSON.parse(JSON.stringify(tour));

    if (tour.description && tour.description.length > 0)
      openAIcall(tour.description, fromLang, toLang)
        .then((resp) => {
          translate_tour.description = resp;
        })
        .catch((err) => console.log("error in translate", err));

    if (tour.location && tour.location.length > 0)
      openAIcall(tour.location, fromLang, toLang)
        .then((resp) => {
          translate_tour.location = resp;
        })
        .catch((err) => console.log("error in translate", err));

    if (tour.main_sentense && tour.main_sentense.length > 0)
      openAIcall(tour.main_sentense, fromLang, toLang)
        .then((resp) => {
          translate_tour.main_sentense = resp;
        })
        .catch((err) => console.log("error in translate", err));

    if (tour.notes && tour.notes.length > 0) {
      tour.notes.map((note, nIdx) => {
        if (note.question)
          openAIcall(note.question, fromLang, toLang)
            .then((resp) => {
              translate_tour.notes[nIdx].question = resp;
            })
            .catch((err) => console.log("error in translate", err));

        if (note.answer)
          openAIcall(note.answer, fromLang, toLang)
            .then((resp) => {
              translate_tour.notes[nIdx].answer = resp;
            })
            .catch((err) => console.log("error in translate", err));
      });
    }

    if (tour.equipment)
      tour.equipment.map((equi, qIdx) => {
        if (equi)
          openAIcall(equi, fromLang, toLang)
            .then((resp) => {
              translate_tour.equipment[qIdx] = resp;
            })
            .catch((err) => console.log("error in translate", err));
      });

    tour.stops.map((stop, stopIdx) => {
      if (stop.direction_text && stop.direction_text.langth > 0) {
        openAIcall(stop.direction_text, fromLang, toLang)
          .then((resp) => {
            translate_tour.stops[stopIdx].direction_text = resp;
          })
          .catch((err) => console.log("error in translate", err));
      }

      if (stop.s_title && stop.s_title.length > 0) {
        openAIcall(stop.s_title, fromLang, toLang)
          .then((resp) => {
            translate_tour.stops[stopIdx].s_title = resp;
          })
          .catch((err) => console.log("error in translate", err));
      }

      if (stop.s_smallDesc && stop.s_smallDesc.length > 0) {
        openAIcall(stop.s_smallDesc, fromLang, toLang)
          .then((resp) => {
            translate_tour.stops[stopIdx].s_smallDesc = resp;
          })
          .catch((err) => console.log("error in translate", err));
      }

      stop.all_media.map((media, mediaIdx) => {
        if (media.title.length > 0) {
          openAIcall(media.title, fromLang, toLang)
            .then((resp) => {
              translate_tour.stops[stopIdx].all_media[mediaIdx].title = resp;
            })
            .catch((err) => console.log("error in translate", err));
        }
        if (media.type.includes("text") && media.content) {
          openAIcall(media.content, fromLang, toLang)
            .then((resp) => {
              translate_tour.stops[stopIdx].all_media[mediaIdx].content = resp;
            })
            .catch((err) => console.log("error in translate", err));
        }
      }); //all_media loop
    }); //stops loop

    setTimeout(() => {
      console.log("translate_tour", translate_tour);
      translate_tour.approve_date = Date();
      firebase
        .firestore()
        .collection("tours")
        .doc(translate_tour.id)
        .set(translate_tour)
        .then(() => {
          //updateApprovDate(tour)
          myResolve(translate_tour);
        })
        .catch((err) => {
          console.log("tour Approve ERROR!", err);
          myReject(err);
        });
    }, 30000);
  });
};
